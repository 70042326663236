/*******************************
         Menu Overrides
*******************************/
#navbar.ui.menu .active.item.navbaritem {
  color: @navbarActiveItemColor;
}

#navbar.ui.menu .item.navbaritem {
  color: @navbarItemColor;
}

#navbar .header h2 {
  color: @navbarItemColor;
}

#navbar.ui.menu .item.navbaritem svg {
  stroke: @navbarItemColor;
}

#navbar.ui.menu .item > i.icon {
  color: @navbarItemColor;
  stroke: @navbarItemColor;
}

#navbar.ui.menu .active.item.navbaritem svg {
  stroke: @navbarActiveItemColor;
}

.ui.vertical-menu.menu .active.item {
  border-left: solid;
  border-color: @primaryColor;
}

.ui.vertical-menu.menu .item {
  padding: 1.2rem;
  background-color:inherit; 
}

.ui.vertical-menu.menu .item.submenu {
  padding: 1.2rem;
  line-height: 0.75;
  font-size: 0.85em;
}

.ui.vertical-menu.menu .item.submenu.first {
  margin-top: 1em;
}

.ui.vertical-menu.menu .item.subMenuHeader {
  padding: 1.2rem;
  padding-bottom: 0;
}

.ui.vertical-menu.menu  .item.submenu:before {
  background: unset !important;
}
