/*******************************
     User Global DTSCB Variables
*******************************/
@fontName          : 'Inter';

/*******************************
     Colors
*******************************/
@primaryColor : #0C1355;
@primaryActiveColor : #8D65401F;
@primaryActiveColorHover : #8D654012;
@secondaryColor : #209DE1;
@loginColor : #072642;
@grey : #586879;
@grey1 : #1C2630;
@grey2: #364657;
@grey5: #CFD7DF;
@grey6 : #E6EBF1;
@white : #ffffff
@yellow : #EEDDB0;
@yellow1 : #D4A93A;
@green : #00841D;
@red : #D62424;
@teal : #0084A0;
@light-blue: #EBF2FD;
@checkGrey : #AAB7C4;
@content-background: #E5E5E5;
@loading-content-background: #0C1355;
@mobileDashboardGrey: #E6EBF1;
@chevron-down: #1C2630;
@profile-card-background: #F7F8FA;
@loginBackground: #0C1355;
@navBarBackground: #0C1355;
@navbarItemColor: #C0C0C0;
@navbarActiveItemColor: #FFFFFF;
@disabledOpacity: 0.6;

