/*******************************
         Site Overrides
*******************************/

a {
  color:@primaryColor;
}

html, body {
  background-color: @content-background;
}

#root .ui.secondary.pointing.menu .active.item {
  border-color: @primaryColor;
  color: @primaryColor;
}

/* Active Hover */
#root .ui.secondary.pointing.menu .active.item:hover {
  border-color: @primaryColor;
  color: @primaryColor;
}

#root .ui.secondary.pointing.menu .item {
  padding: 12px 0;
  margin-right: 16px;
  font-size: 18px;
}

#root .validation-items .item.passed {
  color: @green;
}

#root .validation-items .item.passed i::before {
  color: @green;
}

.ui.header.countrycodeheader:first-child {
  color: @secondaryColor
}

.ui.header.choosecountryheader {
  color: @secondaryColor
}

#registration .ui.list .list>.item>i.icon:only-child, .ui.list>.item>i.icon:only-child {
  color: @checkGrey;
}


#transferBetweeenAccountsConfirmation .ui.list>.item .header {
  color: @secondaryColor;
}

// Confirmation screen
p.estimatedarrival {
  color: @secondaryColor;
}

// Send money - active tab

#sendmoneytab .ui.vertical.menu .active.item {
  background: @primaryActiveColor;
}

#sendmoneytab .ui.vertical.menu .item > i.icon {
  color: @grey;
}

// Bank account details

#bankaccountdetails .ui.list.bankingList > .item .header {
  color: @grey
}

#bankaccountdetails .ui.segment.myaccountsegment {
  background:linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  border: 1px solid #AAB7C4;
  box-shadow: none;
}

#bankaccountdetails .ui.basic.button.walletidBTN{
  background: @grey6 !important;
  color: @primaryColor !important;
  box-shadow: none;
}

#bankaccountdetails .ui.button:not(.icon)>.right.icon:not(.button):not(.dropdown) {
  color: @primaryColor;
}

#bankaccountdetails .ui.labeled.icon.button.walletidBTN > .icon {
  color: @primaryColor;
  background: transparent;
}

  #bankaccountdetails .ui.basic.button.sendbuttonmobile {
    color: @primaryColor !important;
  }

// Footer

#footer a {
  color: @checkGrey;
}

#footer body {
   color: @checkGrey;
}

#footer .footercopyright {
  color: @checkGrey;
}

#footer .ui.bulleted.list > a.item:before {
  color: @checkGrey;
}

// Dashboard - Mobile

 #bankaccountdetails .ui.basic.button.recievebuttonmobile {
  background-color: @mobileDashboardGrey !important;
}

 #bankaccountdetails .ui.basic.button.sendbuttonmobile {
  background-color: @mobileDashboardGrey !important;
}

 #bankaccountdetails .ui.basic.button.depositbuttonmobile {
  background-color: @mobileDashboardGrey !important;
}

 #bankaccountdetails .ui.padded.segment.accountdropdownmobile {
    background: @mobileDashboardGrey;
 }
.color-green {
  color: @green !important;
}

.color-red {
  color: @red !important;
}

.color-grey {
  color: @grey !important;
}

// Profile

#profile-card.ui.card {
  background-color: @profile-card-background;
  border: 1px solid @grey6;
}

.ui.button.discardBTN {
   background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
   border: 1px solid #AAB7C4;
}
.ui.button.discardBTN:hover {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
}

.ui.form.editForm .field>label {
   color: @secondaryColor;
}

#profile-data .ui.icon.button.link-button > .icon
{
  color: @primaryColor;
}

.cardOptionSegment .ui.icon.button.link-button > .icon
{
  color: @primaryColor !important;
}


#openBusinessModal .ui.form .field>label {
  color: @secondaryColor;
}

.ui.message.amountMSG {
  background: @content-background;
}

.businessPaymentLBL {
  color: @secondaryColor;
}

.ui.button:not(.icon.downloadbtnIcon ) > .icon:not(.button):not(.dropdown):not(.arrow.right):not(.arrow.left){
  color: @secondaryColor;
}

// FAQ

 .ui.segment.faqsegment {
   background: @primaryColor;
 }

 .ui.header.faqheader {
   color: @white;
 }

 .ui.button.faqbutton:not(.icon) > .icon:not(.button):not(.dropdown) {
   color: @white;
 }

#accounts-tabs .left-arrow {
    background: linear-gradient(to right, @content-background, transparent);
}

#accounts-tabs .right-arrow {
    background: linear-gradient(to left, @content-background, transparent);
}

 // Security - Account Activity

p.showmore {
  color: @primaryColor;
}

// Security - Device Management

#devicemanagement .ui.button.icon {
   background: none;
 }


// 2FA

a.twofaproblem {
  color: @grey;
}

p.digitp {
  color: @secondaryColor;
}

#accounts-tabs .tabs .item .tab-hold {
  color: @grey;
}

#accounts-tabs .tabs .new button {
  border-color: @grey5 !important;
  border-radius: 0;
}

#accounts-tabs .tabs .new button i {
  color: @primaryColor;
}

#account-data #clipboard-button {
  padding-left: 0;
  box-shadow: none !important;
}

#account-data #clipboard-button i {
  color: @primaryColor;
}

// Create account

#createaccount .ui.form {
  color: @secondaryColor
}

#createaccount .ui.header {
  color: @secondaryColor;
}

// Transaction details

#transactiondetails .ui.button > .icon:not(.button) {
  color: @white;
}

#successCheckIcon {
  fill: @green;
  stroke: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.ui.card.link:hover {
  background: @primaryActiveColor !important;
}

#account {
  opacity: 1;
}

// FAQ topic

.ui.segment.accordionSegment {
  background: @content-background;
}

.ui.fluid.container.faqtopiccontainer {
  background: @white;
}

i.icon.FAQtopicIcon {
  color: @chevron-down;
}

// My Contacts

#mycontacts div .mycontactlistheader {
  color: @grey;
}


#createContact .ui.form .field>label {
  color: @secondaryColor;
}

#createContact .ui.form .field > .selection.dropdown {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
}

#mycontacts .contactListHeader {
 color: @grey;
}



// Transaction statements

.ui.secondary.menu.filtersModalMenu  .activ.item{
  background: @primaryActiveColor ;
  color: @primaryColor ;
  }

 .ui.button.mobileClearBTN {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  border: 1px solid @checkGrey;
}

.ui.selection.dropdown.dropdownMargin {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  border: 1px solid @checkGrey;
  }

#transactionDetailsModal .ui.button.modalBTNS {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  border: 1px solid @checkGrey;
}

#transactionDetailsModal .ui.list>.item .header {
 color: @grey;
}

#transactionstatements .ui.header.headerMargin {
  color: @grey1;
}

#transactionstatements .ui.table thead th {
  color: @grey;
}

#transactionstatements .ui.dropdown.transactiondropdownicon > i.icon {
  color: @grey;
}

#transactionstatements .ui.selection.dropdown {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  border: 1px solid @checkGrey;
}

 #transactionstatements .ui.button.exportButton {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  border: 1px solid @checkGrey;
 }

 #transactionstatements .ui.button.btnsMobile {
   background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
   border: 1px solid #AAB7C4;
   color: @grey1;
  }

// Turnover limits

#profiletable .ui.table thead th {
  color: @grey;
}

#profiletable .ui.header.turnoverLimHeader {
  color: @grey1;
}

#profiletable .ui.label.yellowLabel {
  background-color: @yellow;
}

#profiletable i.icon.yellowStar {
  color: @yellow1;
  width: 18px;
  height: 18px;
}

// New card
#newcard .ui.basic.button.mainbasicbtn {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  color: @grey1;
}

#newcard .ui.form .field > label {
  color: @secondaryColor;
}

#newcard .ui.items > .item.yourcardItem:hover, #newcard .ui.items > .item.yourcardItem.active {
  background: @primaryActiveColor;
}

#newcard .ui.item.menu.yourcardMenu  {
  border: 2px solid @primaryColor;
}

 #newcard .ui.menu.two.item .item  {
  color: @primaryColor;
}

 #newcard .ui.menu .item:hover {
   background: @primaryActiveColor;
 }

  #newcard .ui.menu .active.item {
   background: @primaryColor;

 }

 #newcard .ui.menu .active.item > p:last-child {
   color: @white;
 }

 // New card - details

 #terminateModal .ui.label.emailLabel {
   color: @secondaryColor;
}

 #cardsettingsDashboard  .ui.fluid.button.terminateCardBTN {
    background:linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
    color: @grey1;
    border: 1px solid #AAB7C4;
}


#cardsettingsDashboard  .ui.fluid.button.sendDetailsBTN {
  background:linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  color: @grey1;
  border: 1px solid #AAB7C4;
}

#cardsettingsDashboard  .ui.fluid.button.freezeCardBTN {
  background:linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  color: @grey1;
  border: 1px solid #AAB7C4;
}

#cardsettingsDashboard .cardTypeValue {
  color: @primaryColor;
}

.ui.button.closeWindowBTN {
  background:linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
  color: @grey1;
  border: 1px solid #AAB7C4;
}

.ui.button.closeWindowBTN:hover {
  background:linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
}


  #cardsettingsDashboard .ui.form .field.topupBTN > label {
   color: transparent;
 }

#cardsettingsDashboard .ui.button:not(.icon.downloadbtnIcon ) > .icon:not(.button.settingBTN):not(.dropdown) {
   color: @grey1;
 }

 #cardsettingsDashboard .ui.basic.button {
   color: @grey1;
   background: @profile-card-background;
   border: 0.2px solid @checkGrey;
 }

   #cardsettingsDashboard .ui.form .field > label {
   color: @secondaryColor;
 }

 #cardsettingsDashboard .ui.form .field > .selection.dropdown {
   border: 1px solid @checkGrey;
 }

#cardsettingsDashboard .ui.form .fields .field .ui.input input {
  border: 1px solid @checkGrey;
}

// New card - activate & delivery progress
  .ui.active.progress.deliveryBar .bar {
    background: @primaryColor;
  }

  .ui.indicating.progress.deliveryBar[data-percent^="50"] .bar {
    background: @primaryColor;
  }
  .ui.label.deliveryaddressLabel {
    background: transparent;
  }

  #cardDashboard .secondary {
    color: @grey;
  }

// New card - Dashboard

.ui.segment.cardOptionSegment {
 background: @profile-card-background;
 border: 0px solid transparent;
}
.ui.segment.cardOptionMobileSegment {
 background: @profile-card-background;
 border: 0px solid transparent;
}

.ui.label.changecardLBL {
    background: transparent;
    color: @secondaryColor;
}

p.cardsettingsP {
  color: @secondaryColor;
}

.ui.label.LBLCode {
  color: @secondaryColor;
}

p.terminateMSG {
  color: @secondaryColor;
}

p.LBLCodeMSG {
  color: @secondaryColor;
}

#cardsettingsDashboard .ui.header.currencyH2 {
  color: @primaryColor;
}

#cardsettingsDashboard .ui.form .field .ui.input {
  color: @primaryColor;
}

#cardsettingsDashboard .ui.form .field .ui.input.currencyInput input {
    color: @primaryColor;
}


// Inbox

#messages.selected {
  background-color:  fade(@primaryColor, 12%);
}

#pagination.ui.pagination.menu .item, #pagination.ui.pagination.menu .item.icon.paginationIcon  {
  color: @primaryColor;
}

#messages .unreadicon {
  color: @primaryColor;
}

.notificationCount {
  background-color: @red;
}

#pagination.ui.pagination.menu .active.item {
  background-color: @primaryColor;
}

#myEmployees .ui.selection.dropdown > i.icon {
 color: @grey;
}

#myEmployees .ui.dropdown > .text {
  color: @grey1;
}

#myEmployees .ui.list.employeeDataList .list>.item .header, .ui.list>.item .header {
 color: @grey !important;
}
#myEmployees div .businessProfileLBL {
   color: @grey !important;
}

.employeeListHeader {
 color: @grey;
}

 .ui.basic.table tbody tr.employeeCardRow:hover {
background: #07213A0D
}

#employeeAccordion .ui.button.removeBTN {
  color: @checkGrey;
}

#employeeAccordion .ui.button.removeBTN:hover {
  color: @red;
}

#employeeAccordion .assignCardBTN {
  color: @primaryColor;
}

#employeeAccordion div.acceptDeclineBTNS > .ui.button {
    background:linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
    color: @grey1;
    border: 1px solid #AAB7C4;
}

#createEmployee label.formLabel {
  color: @grey;
}

#createEmployee .employeeRadio input ~ label:before {
  border-color: @primaryColor;
}
#createEmployee .employeeRadio input ~ label:after{
  background: @primaryColor;
}

#createEmployee .syncIcon {
  color: @primaryColor;
}

#createEmployee .ui.checkbox.employeeCodeCheck label {
  color: @grey;
}

#createEmployee span.optionalSpan {
  color: @secondaryColor;
}

#createEmployee .ui.form .field > label {
  color: @secondaryColor;
}

.cardImage {
  color: @primaryColor;
  font-weight: bold;
}

#scrollable-tabs .edit-account-label i {
    color: @primaryColor;
}

#businessCards .edit-account-label i {
    color: @primaryColor;
}

// Navbar
.menuP {
  color: @navbarItemColor;
}

#navbar.ui.menu .active.item.navbarItemMobile {
  background: @primaryActiveColor;
  color: @navbarActiveItemColor;
}


#navbar.ui.menu .item.navbarItemMobile {
  color: @navbarItemColor;
}

#navbar.ui.menu .accountMobileTitleDiv {
  color: @navbarItemColor;
}

#navbar.ui.menu .active.item.navbarItemMobile > .mobileNavbarIcon {
  stroke: @navbarActiveItemColor;
}

#navbar.ui.menu .item.navbarItemMobile > .mobileNavbarIcon {
  stroke: @navbarItemColor;
}

#navbar.ui.menu .accountMobileTitleDiv  > .mobileNavbarIcon {
  stroke: @navbarItemColor;
}

.ui.vertical.menu .menu .item.accordionAccountItem {
  color: @navbarItemColor;
}

svg.myAccountMobileVector:not(:root){
  color: @navbarItemColor;
}

#navbar.ui.menu {
    background-color: @navBarBackground;
}

.ui.modal>.header.navbarModalHeader {
  background-color: @navBarBackground;
}


#mobileNavbar .mobileNavbarBTNDiv > svg {
  fill: @navbarItemColor;
  stroke: @navbarItemColor;
}
#navbarModalMobile .closeMobileNavbarBTN > svg {
  fill: @navbarItemColor;
  stroke: @navbarItemColor;
}

//Stocks
#buyStockBtn {
    background: linear-gradient(180deg, #FFFFFF 0%, #F7F8FA 100%);
    border: 2px solid @primaryColor;
    color: @primaryColor;
}

#file-container {
    background: rgba(7, 33, 58, 0.05);
    text-color: @grey1;
}

code.greyBackground {
  background: #f1f4f7;
}

.tableBody .tableActionIcon {
  margin-right: 0.25em;
}

.tableBody .tableActionIcon:hover {
  color: @primaryColor;
  cursor: pointer;
}

.actionIcon {
  margin-left: 0.5em !important;
}

.actionIcon:hover {
  color: @primaryColor;
  cursor: pointer;
}

.signoutIcon:hover {
  color: @primaryColor;
  cursor: pointer;
}

//reconciliations
#reconciliations .editable-cell {
	background: @content-background;
}

#reconciliations .editable-cell:hover {
	outline-color: @primaryColor;
}

//balances

#balance-tree .grid-custom-headers div {
  background: @content-background
}

#balance-tree .accordion.ui.styled.fluid
 {
	border-top: 1px solid @content-background;
}

#balance-tree .balance-content .balance-row:nth-child(odd) {
	background-color: @content-background;
}
